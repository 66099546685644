import * as Sentry from '@sentry/sveltekit';
import {captureConsoleIntegration, replayIntegration} from "@sentry/sveltekit";
import {initTawk} from "$lib/third-party/tawk.js";
import {initMatomo} from "$lib/third-party/matomo.js";

const dev = import.meta.env.DEV
const disabledViaLocalStorage = localStorage.getItem('disableScripts') !== null

if (dev || disabledViaLocalStorage) {
    console.warn('### HOOKS third-party scripts disabled via ' + (dev ? 'DEV MODE' : 'localStorage'))
} else {
    Sentry.init({
        dsn: "https://aa3d8f8497c3d8bd9eb25ffabaecad26@monitor.vocabeo.com/2",
        tracesSampleRate: 1,
        replaysSessionSampleRate: 1,
        replaysOnErrorSampleRate: 1,
        integrations: [
            replayIntegration({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
            }),
            captureConsoleIntegration({levels: ['error']})
        ],
    })
    initMatomo()
    initTawk()
}