export function initTawk() {
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/62ebec4437898912e961426d/1g9kp5quf';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
    })();

    Tawk_API.customStyle = {
        visibility: {
            mobile: {
                xOffset: 10,
                yOffset: 60,
                position: 'br',
            },
        }
    }
}